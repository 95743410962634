import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { ToastOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private defaultDuration: number = 5000;
  private defaultPosition: string = 'bottom';

  constructor(
    private toastController: ToastController
  ) { }

  async present(toastMessage: string, error: boolean = false, position: any = this.defaultPosition, duration: number = this.defaultDuration) {
    this.presenter(toastMessage, error, position, duration);
  }

  async presentError(toastMessage: string, error: boolean = true, position: any = this.defaultPosition, duration: number = this.defaultDuration) {
    this.presenter(toastMessage, error, position, duration);
  }

  private async presenter(toastMessage: string, error: boolean = false, position: any = this.defaultPosition, duration: number = this.defaultDuration) {
    let toast = await this.toastController.create({
      message: toastMessage,
      duration: duration,
      position: position,
      cssClass: error ? 'errorToastClass' : ''
    })

    toast.present();
  }
}
