import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
    private router: Router
  ) {
  }

  async showConfirm(header: string, message: string, okHandler: () => void, navPath: string) {
    let alertConfirm = await this.alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            if(okHandler) okHandler();
            if(navPath) this.router.navigate([navPath]);
          }
        }
      ]
    });

    alertConfirm.present();
  }

  async showPrompt(header: string, message: string, yesHandler: () => void, noHandler: () => void) {
    let alertConfirm = await this.alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'Yes, Continue',
          handler: () => {
            yesHandler();
          }
        },
        {
          text: 'No, Cancel',
          role: 'cancel',
          handler: () => {
            noHandler();
          }
        }
      ]
    });

    alertConfirm.present();
  }

  async showError(header: string, message: string) {
    let alertError = await this.alertController.create({
      header: 'Error: ' + header,
      message: message,
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
          handler: () => {
          }
        }
      ]
    });

    alertError.present();
  }
}
