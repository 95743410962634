import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as AvatarName from 'avatar-name';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../rest/authentication-service';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  constructor(
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient
  ) { }

  drawAvatar(ctx: CanvasRenderingContext2D) {
    this.angularFireAuth.authState.subscribe(result => {
      let img = new Image();

      img.onload = function () {
        ctx.drawImage(img, 0, 0, 40, 40);
      };

      if (result && result.uid) {
        this.getOutlookUserPhoto(AuthenticationService.msToken).subscribe(
          result => {
            img.src = window.URL.createObjectURL(result);
          },
          error => {
            img.src = 'https://api.adorable.io/avatars/128/' + result.email + '.png';
          },
          () => {
          });
      }
      else {
        let la = AvatarName('?', 30, '#0000FF', '#FFFFFF');
        img.src = la;
      }
    });
  }

  getOutlookUserPhoto(token: string) {
    var url = 'https://graph.microsoft.com/v1.0/me/photo/$value';

    let httpHeaders = new HttpHeaders();

    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + token);

    var response = this.http.get(url, { headers: httpHeaders, responseType: 'blob' });

    return response;
  }
}
