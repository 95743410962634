import { Injectable } from '@angular/core';
import * as jsonexport from 'jsonexport/dist'

@Injectable({
  providedIn: 'root'
})
export class ReportUtilityService {

  constructor() { }

  static print(reportTitle: string) {
    var tableToPrint = document.getElementById('areaToPrint');
    var newWin = window.open('');
    newWin.document.write('<html><head><title>' + reportTitle + '</title><link rel="stylesheet" type="text/css" href="assets/css/job-details.css"></head><body>');
    newWin.document.write(tableToPrint.outerHTML);
    newWin.document.write('</body></html>');

    setTimeout(() => {
      newWin.print();
      newWin.close();
    }, 500);
  }

  static export(exportData: any) {
    jsonexport(exportData, (error, csv) => {
      if (error) {
        console.error(error);
      }
      else {
        this.download('export.csv', csv);
      }
    });
  }

  static download(filename, text) {
    var pom = document.createElement('a');

    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    }
    else {
      pom.click();
    }
  }
}
