import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TestDetailsComponent } from './test-details/test-details.component';
import { CommonModule } from '@angular/common';
import { EditGLComponent } from './edit-gl/edit-gl.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
	declarations: [
		TestDetailsComponent,
		EditGLComponent
	],
	imports: [
		IonicModule,
		CommonModule,
		ReactiveFormsModule,
		FormsModule
	],
	exports: [
		TestDetailsComponent,
		EditGLComponent
	]
})
export class ComponentsModule { }
